import React from 'react'
import "normalize.css"
//import { Link } from 'gatsby'
import testimonialStyles from './sass/testimonials.module.scss'
import VideoMP4 from '../posts/assets/video.mp4'
import VideoWebm from '../posts/assets/video.webm'

 const Testimonials = () => {
     return (
         <div className={testimonialStyles.sectionTestimonials}>
            <div className={testimonialStyles.bgVideo}>
                <video className={testimonialStyles.bgVideoContent} autoPlay muted loop>
                    <source src={VideoMP4} type="video/mp4" ></source>
                    <source src={VideoWebm} type="video/webm" ></source>
                </video>
                </div>
            <div className={[testimonialStyles.centerText, testimonialStyles.marginBottom].join(' ')}>
                <h2 className={testimonialStyles.headingSecondary}>
                    Testimonials
                </h2>
            </div>
            <div className={testimonialStyles.row}>
                <div className={testimonialStyles.testimonial}>
                    <figure className={testimonialStyles.testimonialShape}>
                        <img src={require('../posts/assets/yeral.jpg')} alt="Person on a tour" className={testimonialStyles.testimonialImage}/>
                        <figcaption className={testimonialStyles.testimonialCaption}>Yeraldin Rosas</figcaption>
                    </figure>
                    <div className={[testimonialStyles.testimonialText, testimonialStyles.marginBottom].join(' ')}>
                        <h3 className={testimonialStyles.headingTertiary}>What other people think of me ?</h3>
                        <p>Walter is a brilliant software engineer! He is committed, responsible, and has passion for what he does! I believe that any company would be lucky to have him on board.</p>
                    </div>
                </div>
            </div>
            <div className={testimonialStyles.row}>
                <div className={testimonialStyles.testimonial}>
                    <figure className={testimonialStyles.testimonialShape}>
                        <img src={require('../posts/assets/damaris.jpg')} alt="Person on a tour" className={testimonialStyles.testimonialImage}/>
                        <figcaption className={testimonialStyles.testimonialCaption}>Damaris Moran</figcaption>
                    </figure>
                    <div className={[testimonialStyles.testimonialText, testimonialStyles.marginBottom].join(' ')}>
                        <h3 className={testimonialStyles.headingTertiary}>What other people think of me ?</h3>
                        <p>I've worked with Walter in the same team and i can say that he's good at software development, with qualities like attention to detail, proactive, always ready to help others, fast learner, creative and much more.</p>
                    </div>
                </div>
            </div>
            <div className={[testimonialStyles.centerText, testimonialStyles.marginTopBig].join(' ')}> 
                {/*<Link to={"#"} className={[testimonialStyles.buttonText].join(' ')}>Read all testimonials &rarr;</Link>*/}
            </div> 
         </div>
     )
 }

 export default Testimonials